import dynamic from 'next/dynamic';
import { GetStaticPaths, GetStaticProps } from 'next';

// Helpers
import { getLocalePaths } from '@i18n';

import WithRoles, { rolesEnum } from '@HOCs/WithRole';
import { CustomGetStaticPathsResult, CustomGetStaticPropsResult } from '../../types';

const HomePage = dynamic(import('@components/pages/home/epnbz'));
const WebmasterPage = dynamic(import('@components/pages/home/epncpa'));

const IndexPageSwitcher: React.FC = () => {
    if (process.env.NEXT_PUBLIC_PROJECT_NAME === 'epncpa') {
        return <WebmasterPage />;
    }
    return <HomePage />;
};

export const getStaticProps: GetStaticProps = async ({
    params,
}): Promise<CustomGetStaticPropsResult> => {
    return { props: { locale: params?.locale } };
};

export const getStaticPaths: GetStaticPaths = async (): Promise<CustomGetStaticPathsResult> => {
    return { paths: getLocalePaths(), fallback: false };
};

export default WithRoles(IndexPageSwitcher, [rolesEnum.guest]);
